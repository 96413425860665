import { Spinner, Table } from 'react-bootstrap';
import Layout from '../../components/Layout/Layout';
import AdminRoute from '../../routes/AdminRoute';
import './SalesPage.css';
import { useEffect, useState } from 'react';
import axiosClient from '../../settings/axiosClient';
import PopUp from '../../components/PopUp/PopUp';

const SalesPage = () => {
  const [allOrders, setAllOrders] = useState([]);
  const [allSales, setAllSales] = useState([]); //ventas externas a la app
  const [spinner, setSpinner] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorPopUp, setErrorPopUp] = useState(false);
  const [deliveryDates, setDeliveryDates] = useState([]);

  const getData = async () => {
    setSpinner(true);
    try {
      const orderResponse = await axiosClient.get("/orders");
      const extSalesResponse = await axiosClient.get('/sale'); //VENTAS EXTERNAS A LA APP
      if(orderResponse.status === 200 && extSalesResponse.status === 200){
        //SALES
        const allSalesSup = extSalesResponse.data.map(sale => ({...sale, deliveryDate: sale.createdAt.substring(0, 10).split('-').reverse().join('/')})); //para poder acomodarla en la tabla
        setAllSales(allSalesSup);
        //ORDERS
        const allOrdersSup = orderResponse.data.filter(order => !order.stockOut); //Dejo afuera las ordenes que se van a eliminar de la DB
        setAllOrders(allOrdersSup);
        const dates = [...new Set(allOrdersSup.map(order => order.deliveryDate).concat(allSalesSup.map(sale => sale.deliveryDate)))]; //Obtengo las fechas de todo lo que se vendio
        const datesSorted = dates.map(date => (
          {
            date: date,
            dateTime: new Date(date.split('/').reverse().join('-') + 'T15:00:00.000+00:00').getTime(),
          }
        )).sort((a, b) => a.dateTime - b.dateTime).map(date => date.date);
        setDeliveryDates(datesSorted); //Obtengo las fechas de todo lo que se vendio ordenadas cronologicamente
        setSuccess(true);
      }
    } catch (error) {
      setErrors({server: 'Error en el servidor, intente nuevamente.'});
    }
  }

  const errorPopUpFunction = () => {
    setErrorPopUp(false);
    setErrors({});
  }

  useEffect(() => {
    if(allOrders.length === 0) getData();
  }, [])

  useEffect(() => {
    if(success){
      setSuccess(false);
      setSpinner(false);
    }
  }, [success])

  useEffect(() => {
    if(Object.keys(errors).length !== 0){
      setSpinner(false);
      setErrorPopUp(true);
    }
  }, [errors])

  return (
    <Layout>
      <AdminRoute>
        <div className="salesPage-style pt-5">
          {
            spinner ? <div className="form-spinner"><Spinner /></div> : null
          }
          <p className='fw-bold ms-3'>TOTAL VENTAS: {new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(Number(allOrders.concat(allSales).reduce((acc, order) => acc + order.totalPrice, 0)))}</p>
          <Table striped bordered className='addressViewTable-style mt-2'>
            <thead>
              <tr>
                <th>Fecha</th>
                <th className='text-center'>Efectivo</th>
                <th className='text-center'>Banco</th>
                <th className='text-center'>Venta Extr.</th>
                <th className='text-center'>Pendiente</th>
                <th className='text-center'>TOTAL</th>
              </tr>
            </thead>
            <tbody>
              {
                deliveryDates.map((date, index) => (
                  <tr key={index}>
                    <td>{date}</td>
                    <td className='text-center'>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(Number(allOrders.filter(order => order.deliveryDate === date && !order.stockOut && order.status === "cashed").reduce((acc, order) => acc + order.totalPrice, 0)))}</td>
                    <td className='text-center'>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(Number(allOrders.filter(order => order.deliveryDate === date && !order.stockOut && order.status === "transfer").reduce((acc, order) => acc + order.totalPrice, 0)))}</td>
                    <td className='text-center'>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(Number(allSales.filter(order => order.deliveryDate === date).reduce((acc, order) => acc + order.totalPrice, 0)))}</td>
                    <td className='text-center'>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(Number(allOrders.concat(allSales).filter(order => order.deliveryDate === date && (order.status === "cash" || order.status === "pending")).reduce((acc, order) => acc + order.totalPrice, 0)))}</td>
                    <td className='text-center'>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(Number(allOrders.concat(allSales).filter(order => order.deliveryDate === date).reduce((acc, order) => acc + order.totalPrice, 0)))}</td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
          <PopUp popUp={errorPopUp} setPopUp={setErrorPopUp} popUpTitle={"ERROR"} popUpText={Object.values(errors).join(', ')} redBorder={true} popUpBtnFunction={errorPopUpFunction} popUpBtnText={"OK"} noWayOfClose={true} />
        </div>
      </AdminRoute>
    </Layout>
  );
};

export default SalesPage;