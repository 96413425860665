import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { firstUpperCase } from '../../helpers/firstUpperCase';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import SubStockReadRow from './SubStockReadRow';

const StockReadRow = ({product, stock, setErrors, setSpinner, setSuccess, allOrders, setStockSelected, wasteArray, extSales}) => {
  const [decompress, setDecompress] = useState(false);
  const [productStock, setProductStock] = useState([]);
  const [productStockDB, setProductStockDB] = useState([]);
  const DAY_LIMIT = 10 * 24 * 60 * 60 * 1000; // 10 days
  const [productWholeCost, setProductWholeCost] = useState(0);
  const [productWholeSales, setProductWholeSales] = useState(0);
  const [productWholeProfit, setProductWholeProfit] = useState(0);

  const handleChange = (e, entryId) => {
    setProductStock(productStock.map(entry => entry._id === entryId ? {...entry, wholeQuantity: Number(e.target.value) === 0 ? "" : Number(e.target.value)} : entry));
  }

  const handleKeyDown = (e) => {
    if(e.keyCode === 27){
      setProductStock(productStockDB);
      e.target.blur();
    }
  }

  useEffect(() => {
    setProductStock(stock.filter(entry => entry.itemId === product._id && entry.createdAt.substring(0, 10) !== "2024-10-13" && entry.createdAt.substring(0, 10) !== "2024-10-12"));
    setProductStockDB(stock.filter(entry => entry.itemId === product._id && entry.createdAt.substring(0, 10) !== "2024-10-13" && entry.createdAt.substring(0, 10) !== "2024-10-12"));
  }, [product, stock])

  useEffect(() => {
    if(productStockDB.length !== 0){
      setProductWholeCost(productStockDB.filter(entry => entry.wholeQuantityUpdated === 0).reduce((acc, item) => acc + item.wholePrice, 0)*1.1); //DE ESTA FORMA SACO LOS VALORES DE COSTO Y VENTA SOLO DE LOS LOTES QUE ESTAN AGOTADOS
      const stockIds = productStockDB.filter(entry => entry.wholeQuantityUpdated === 0).map(item => item._id);
      const productOrders = allOrders.filter(order => order.orderPlaced.map(item => item.stocks.map(stock => stock.stockId)).flat().some(stockId => stockIds.includes(stockId)));
      const saleOrders = extSales.filter(order => order.orderPlaced.map(item => item.stocks.map(stock => stock.stockId)).flat().some(stockId => stockIds.includes(stockId)));
      const stockData = productOrders.map(order => (
        {
          amountSold: order.orderPlaced.find(item => item.stocks.find(i => stockIds.includes(i.stockId))).pricePerUnit * order.orderPlaced.map(item => item.stocks.filter(stock => stockIds.includes(stock.stockId)).map(stock => stock.stockQuantity)).flat().reduce((acc, item) => acc + item, 0)
        }
      ));
      const saleData = saleOrders.map(order => (
        {
          amountSold: order.orderPlaced.find(item => item.stocks.find(i => stockIds.includes(i.stockId))).pricePerUnit * order.orderPlaced.map(item => item.stocks.filter(stock => stockIds.includes(stock.stockId)).map(stock => stock.stockQuantity)).flat().reduce((acc, item) => acc + item, 0)
        }
      ));
      setProductWholeSales(stockData.reduce((acc, item) => acc + item.amountSold, 0) + saleData.reduce((acc, item) => acc + item.amountSold, 0)); //esto lo hago para sacar el rendimiento general del producto
    };
  }, [productStockDB])

  useEffect(() => {
    if(productWholeCost !== 0 && productWholeSales !== 0){
      setProductWholeProfit(Number((productWholeSales/productWholeCost-1)*100).toFixed(1));
    }
  }, [productWholeCost, productWholeSales])

  return (
    <>
      <tr>
        <td className="text-center"><FontAwesomeIcon className={`pointer ${decompress ? "text-warning" : null}`} icon={decompress ? faAngleUp : faAngleDown} onClick={() => setDecompress(!decompress)} /></td>
        <td></td>
        <td></td>
        <td>{decompress ? product.productName : firstUpperCase(product.productName)}</td>
        <td></td>
        <td></td>
        <td className='text-center'>{Number(productStock.reduce((acc, item) => acc + item.wholeQuantityUpdated, 0)).toFixed(2)}</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>{/*Number(productWholeCost).toFixed(0)*/}</td>
        <td>{/*Number(productWholeSales).toFixed(0)*/}</td>
        <td className='text-center'><span className={productWholeProfit < 0 ? "text-danger" : "text-success"}>{productWholeProfit === 0 ? "-" : productWholeProfit}</span></td>
      </tr>
        {
          productStock?.map((entry, index) => (
            <SubStockReadRow decompress={decompress} entry={entry} DAY_LIMIT={DAY_LIMIT} setSpinner={setSpinner} setErrors={setErrors} setSuccess={setSuccess} showUpdateBtn={productStock.find(item => item._id === entry._id).wholeQuantity.toString() === productStockDB.find(item => item._id === entry._id).wholeQuantity.toString() || productStock.find(item => item._id === entry._id).wholeQuantity === 0 || productStock.find(item => item._id === entry._id).wholeQuantity === ""} allOrders={allOrders} handleKeyDown={handleKeyDown} handleChange={handleChange} setStockSelected={setStockSelected} wasteArray={wasteArray} extSales={extSales} key={index} />
          ))
        }
    </>
  );
};

export default StockReadRow;