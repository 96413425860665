import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import ProductsContext from "../../context/Products/ProductsContext";

const SubStockReadRow = ({decompress, entry, DAY_LIMIT, setSpinner, setErrors, setSuccess, showUpdateBtn, allOrders, handleKeyDown, handleChange, setStockSelected, wasteArray, extSales}) => {
  const { updateStockQuantity } = useContext(ProductsContext);
  const stockOrders = allOrders.filter(order => order.orderPlaced.map(item => item.stocks.map(stock => stock.stockId)).flat().includes(entry._id));
  const stockOrdersDelivered = allOrders.filter(order => order.orderStatus === "orderow" || order.orderStatus === "orderreceived").filter(order => order.orderPlaced.map(item => item.stocks.map(stock => stock.stockId)).flat().includes(entry._id));
  const stockPendingOrders = allOrders.filter(order => order.orderStatus === "orderplaced" || order.orderStatus === "orderrtpu").filter(order => order.orderPlaced.map(item => item.stocks.map(stock => stock.stockId)).flat().includes(entry._id));
  const extSaleData = extSales.filter(order => order.orderPlaced.map(item => item.stocks.map(stock => stock.stockId)).flat().includes(entry._id));
  
  const stockData = (orders) => {
    const stockData = orders.map(order => (
      {
        user: order.owner,
        quantity: order.orderPlaced.map(item => item.stocks.filter(stock => stock.stockId === entry._id).map(stock => stock.stockQuantity)).flat().reduce((acc, item) => acc + item, 0),
        pricePerUnit: order.orderPlaced.find(item => item.stocks.find(i => i.stockId === entry._id)).pricePerUnit,
        amountSold: order.orderPlaced.find(item => item.stocks.find(i => i.stockId === entry._id)).pricePerUnit * order.orderPlaced.map(item => item.stocks.filter(stock => stock.stockId === entry._id).map(stock => stock.stockQuantity)).flat().reduce((acc, item) => acc + item, 0)
      }
    ));
    return stockData;
  }

  const updateQuantity = async (entry) => {
    setSpinner(true);
    const updateQuantityError = await updateStockQuantity(entry._id, entry.wholeQuantity);
    if(Object.keys(updateQuantityError).length !== 0){
      if(updateQuantityError.alreadyUpdated){
        setErrors(updateQuantityError);
      }
      if(updateQuantityError.server){
        setErrors(updateQuantityError);
      }
    }else{
      setSuccess(true);
    }
  }

  return (
    <tr className={`${!decompress ? "d-none" : null} ${entry.wholeQuantityUpdated === 0 ? "text-danger" : null}`}>
      <td className="text-center bg-decomp"></td>
      <td className="text-center bg-decomp">{entry._id.substring(entry._id.length-5)}</td>
      <td className="text-center bg-decomp">{entry.createdAt.substring(0, 10)}</td>
      <td className="bg-decomp"></td>
      <td className='wpx-60 p-0'>
        <div className="d-flex align-items-center justify-content-center input-group hpx-40">
          <input autoComplete="off" disabled={((new Date().getTime()) - (new Date(entry.createdAt).getTime()) > DAY_LIMIT) || entry.quantityUpdateSign} className="text-center form-control input-noborder w-100 h-100" type="number" value={entry.wholeQuantity} onKeyDown={handleKeyDown} onChange={(e) => handleChange(e, entry._id)} />
        </div>
      </td>
      <td className="text-center bg-decomp"><FontAwesomeIcon icon={faRefresh} className={`pointer ${showUpdateBtn ? "d-none" : ""}`} onClick={() => updateQuantity(entry)} /></td>
      <td className="text-center bg-decomp">{Number(entry.wholeQuantityUpdated).toFixed(2)}</td>
      <td className="text-center bg-decomp d-flex justify-content-center align-items-center">
        <div>{Number(wasteArray.filter(item => item.stockId === entry._id).reduce((a, b) => a + b.quantity, 0)).toFixed(2)}</div>
        <div className={entry.wholeQuantityUpdated === 0 ? "d-none" : ""}><span className="border border-dark px-2 pointer bg-light waste-btn ms-3 rounded" onClick={() => setStockSelected({...entry, sold: Number(stockData(stockOrdersDelivered).reduce((acc, item) => acc + item.quantity, 0)).toFixed(2), soldPend: Number(stockData(stockPendingOrders).reduce((acc, item) => acc + item.quantity, 0)).toFixed(2)})}>+</span></div>
      </td>
      <td className="text-center bg-decomp"><span className="text-decoration-underline pointer" onClick={() => console.log(stockData(stockOrdersDelivered))}>{Number(stockData(stockOrdersDelivered).reduce((acc, item) => acc + item.quantity, 0)).toFixed(2)}</span></td>{/*sumo las cantidades de ese producto de las ordenes entregadas*/}
      <td className="text-center bg-decomp">{Number(stockData(stockPendingOrders).reduce((acc, item) => acc + item.quantity, 0)).toFixed(2)}</td>{/*sumo las cantidades de ese producto de las ordenes pendientes*/}
      <td className="text-center bg-decomp">{Number(stockData(extSaleData).reduce((acc, item) => acc + item.quantity, 0)).toFixed(2)}</td>{/*calculo la venta externa de ese lote*/}
      <td className="text-center bg-decomp">{entry.unit}</td>
      <td className="text-center bg-decomp">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(Number(entry.wholePrice*1.1))}</td>
      <td className="text-center bg-decomp">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(Number(stockData(stockOrders).reduce((acc, item) => acc + item.amountSold, 0))+Number(stockData(extSaleData).reduce((acc, item) => acc + item.amountSold, 0)))}</td>{/*sumo el total de las ventas externas de ese lote tambien*/}
      <td className="text-center bg-decomp"><span className={stockData(stockOrders).reduce((acc, item) => acc + item.amountSold, 0) < entry.wholePrice*1.1 ? "text-danger" : "text-success"}>{Number(((stockData(stockOrders).reduce((acc, item) => acc + item.amountSold, 0)+stockData(extSaleData).reduce((acc, item) => acc + item.amountSold, 0))/(entry.wholePrice*1.1)-1)*100).toFixed(1)}</span></td>
    </tr>
  );
};

export default SubStockReadRow;