import { Button, Form, Spinner } from 'react-bootstrap';
import Layout from '../../components/Layout/Layout';
import AdminRoute from '../../routes/AdminRoute';
import './StockOutPage.css';
import stringSimilarity from 'string-similarity';
import { useContext, useEffect, useRef, useState } from 'react';
import ProductsContext from '../../context/Products/ProductsContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import UserContext from '../../context/Users/UserContext';
import axiosClient from '../../settings/axiosClient';
import PopUp from '../../components/PopUp/PopUp';
import { useNavigate } from 'react-router-dom';

const StockOutPage = () => {
  const { stock, products, getStock, getAllProducts } = useContext(ProductsContext);
  const [success, setSuccess] = useState(false);
  const [spinner, setSpinner] = useState(true);
  const [errors, setErrors] = useState({});
  const [bestSuggestion, setBestSuggestion] = useState([]);
  const [errorPopUp, setErrorPopUp] = useState(false);
  const [productNameSelected, setProductNameSelected] = useState('');
  const [productSelected, setProductSelected] = useState({});
  const [productQuantity, setProductQuantity] = useState(0);
  const [stockFiltered, setStockFiltered] = useState([]);
  const [stockOut, setStockOut] = useState({
    itemId: '',
    quantity: 0,
    pricePerUnit: 0,
    soldUnit: ''
  });
  const [stockOutList, setStockOutList] = useState([]);
  const [causeNote, setCauseNote] = useState('');
  const ref = useRef(null);
  const [sendDataError, setSendDataError] = useState({});
  const { sign } = useContext(UserContext);
  const [dbErrors, setDbErrors] = useState({});
  const [successPopUp, setSuccessPopUp] = useState(false);
  let navigate = useNavigate();


  const handleSubmit = (e) => {
    e.preventDefault();
    if(stockOutList.find(item => item.itemId === stockOut.itemId)) return setErrors({stockOut: "Este producto ya ha sido añadido a la lista."});
    if(stockOut.quantity > productQuantity) return setErrors({stockOut: "No hay suficiente Stock del producto."});
    setStockOutList([stockOut, ...stockOutList]);
    if(Object.keys(productSelected).length !== 0) setProductSelected({});
    if(stockFiltered.length !== 0) setStockFiltered([]);
    setStockOut({itemId: '', quantity: 0, pricePerUnit: 0, soldUnit: ''});
    setProductNameSelected('');
    ref.current?.focus();
  }

  const handleChange = (e) => {
    if(Object.keys(productSelected).length !== 0) setProductSelected({});
    if(stockFiltered.length !== 0) setStockFiltered([]);
    setStockOut({itemId: '', quantity: 0, pricePerUnit: 0, soldUnit: ''});
    setProductNameSelected(e.target.value);
    const filter = stringSimilarity.findBestMatch(e.target.value.toLowerCase(), products.map(product => product.productName.toLowerCase())).ratings.filter(item => item.rating > 0.3).sort((a, b) => b.rating - a.rating);
    setBestSuggestion(filter.length !== 0 ? filter.map(item => item.target) : []);
  }

  const handleKeyDown = (e) => {
    if((e.keyCode === 9 || e.keyCode === 13) && bestSuggestion.length !== 0){
      e.preventDefault();
      setProductNameSelected(bestSuggestion[0]);
      const product = products.find(product => product.productName.toLowerCase() === bestSuggestion[0].toLowerCase());
      setProductSelected(product);
      const stockFiltered = stock.filter(item => item.itemId === product?._id && item.wholeQuantityUpdated > 0).reverse();
      setStockFiltered(stockFiltered)
      setProductQuantity(stockFiltered.length !== 0 ? stockFiltered.reduce((acc, item) => acc + item.wholeQuantityUpdated, 0) : 0);
      if(stockFiltered.length !== 0){
        setStockOut({...stockOut, itemId: product._id, soldUnit: product.unit, pricePerUnit: Number(Number(stockFiltered[0].wholePrice/(stockFiltered[0].wholeQuantity - stockFiltered[0].waste)).toFixed(2))});
      }else{
        setStockOut({...stockOut, itemId: product._id, soldUnit: product.unit, pricePerUnit: 0});
      }
      setBestSuggestion([]);
    }
  }

  const getStockFunction = async () => {
    setSpinner(true);
    const getProductsErrors = await getAllProducts();
    const getStockErrors = await getStock();
    if(Object.keys(getStockErrors). length !== 0 || Object.keys(getProductsErrors). length !== 0) return setDbErrors({servidor: "Error en el servidor, intente nuevamente."});
    setSuccess(true);
  }

  const sendData = async () => {
    if(stockOutList.length === 0) return;
    if(causeNote.length === 0) return setSendDataError({causeNote: "Es necesario ingresar un motivo de venta extraordinaria."});
    if(stockOutList.map(item => item.pricePerUnit).find(price => price === 0) === 0) return setSendDataError({causeNote: "Es necesario ingresar un precio de salida distinto de $0 para todos los productos."});
    const totalPrice = Number(stockOutList.reduce((acc, item) => acc + item.pricePerUnit*item.quantity, 0).toFixed(2));
    const dataDB = {
      sign: sign,
      totalPrice,
      orderPlaced: stockOutList,
      orderNote: causeNote,
      status: "pending",
    }
    setSpinner(true);
    try {
      const response = await axiosClient.post('/sale', dataDB);
      if(response.status === 200){
        setSuccess(true);
        setStockOutList([]);
        setCauseNote('');
        setSuccessPopUp(true);
      }
    } catch (error) {
      setDbErrors({dbError: "Error en el servidor, intente nuevamente."});
    }
  }

  const popUpBtnFunction = () => {
    setErrorPopUp(false);
    setDbErrors({});
  }

  useEffect(() => {
    if(products.length === 0) getStockFunction();
  }, [])

  useEffect(() => {
    if(Object.keys(dbErrors).length !== 0){
      setSpinner(false);
      setErrorPopUp(true);
    }
  }, [dbErrors])

  useEffect(() => {
    if(Object.keys(errors).length !== 0){
      setTimeout(() => {
        setSpinner(false);
      }, 1500)
    }
  }, [errors])

  useEffect(() => {
    if(success){
      setSpinner(false);
      setSuccess(false);
      
    }
  }, [success])

  useEffect(() => {
    if(Object.keys(errors).length !== 0) setErrors({});
  }, [stockOut])

  useEffect(() => {
    if(Object.keys(sendDataError).length !== 0) setSendDataError({});
  }, [causeNote])

  return (
    <Layout>
      <AdminRoute>
        <div className='stockOut-style'>
          {
            spinner ? <div className="form-spinner"><Spinner /></div> : null
          }
          <Form onSubmit={handleSubmit} className='stockOut-form'>
            <div className='d-flex w-100'>
              <Form.Group className="w-500" controlId="formBasicProduct">
                <Form.Label>Producto</Form.Label>
                <Form.Control ref={ref} disabled={products.length === 0 || stock.length === 0} autoComplete='off' type="text" placeholder="Producto" onChange={handleChange} onKeyDown={handleKeyDown} value={productNameSelected} />
              </Form.Group>
              <Form.Group controlId="formBasicQuantity" className='ms-auto'>
                <Form.Label>Cantidad</Form.Label>
                <Form.Control step=".01" type="number" placeholder="Cantidad" min={0} onChange={(e) => setStockOut({...stockOut, quantity: Number(e.target.value) === 0 ? "" : Number(e.target.value)})} value={stockOut.quantity} />
              </Form.Group>
              <Form.Group controlId="formBasicUnit" className='wpx-40 ms-2'>
                <Form.Label className='invisible'>C</Form.Label>
                <Form.Control disabled type="text" value={productSelected.unit ?? ""} />
              </Form.Group>
            </div>
            {
              bestSuggestion.length !== 0 && (
                <div className='w-100 user-order-input stockOut-form'><small className='text-muted'><span className='text-success'>{bestSuggestion[0]}</span> <span className='fst-italic'>"presione tab para elegir la sugerencia"</span><span>, {bestSuggestion.slice(1).length !== 0 ? `[${bestSuggestion.slice(1).join(', ')}]` : null}</span></small></div>
              )
            }
            {
              Object.keys(productSelected).length !== 0 && (
                <div className='w-100 mt-3'>
                  <span className={productQuantity === 0 ? "text-danger" : ""}>Stock Restante Total: {productQuantity} {productSelected.unit === "U" ? "Unidades" : "Kg"}</span>
                  {
                    stockFiltered?.map((item, index) => (
                      <div key={index}>
                        <span>Stock {index+1}: {item.wholeQuantityUpdated} {item.unit === "U" ? "Unidades" : "Kg"}</span>
                        <span>  ||  Venta: ${Number(item.salesPricePerUnit).toFixed(2)}</span>
                        <span>  ||  Costo: ${Number(item.wholePrice/(item.wholeQuantity - item.waste)).toFixed(2)}</span>
                      </div>
                    ))
                  }
                </div>
              )
            }
            <div className='d-flex justify-content-between'>
              <Form.Group controlId="formBasicPrice" className='mt-4 wpx-120'>
                <Form.Label>Precio Salida / U</Form.Label>
                <Form.Control type="number" onChange={(e) => setStockOut({...stockOut, pricePerUnit: Number(e.target.value)})} value={Number(stockOut.pricePerUnit)} />
              </Form.Group>
              <Form.Group controlId="formBasicPrice" className='mt-4 wpx-120 ms-5'>
                <Form.Label>Precio Total Salida</Form.Label>
                <Form.Control type="text" disabled value={new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(Number(stockOut.pricePerUnit*stockOut.quantity).toFixed(2))} />
              </Form.Group>
            </div>
            <Button variant='outline-dark' className='w-100 mt-4' type="submit" disabled={stockOut.quantity === 0}>
              Agregar salida de Stock
            </Button>
            {
              Object.keys(errors).length !== 0
                ? Object.values(errors).map((error, index) => (
                  <div className="border-danger w-100 text-danger text-center mt-3 thick-border rounded" key={index}>{error}</div>
                  )) : null
            }
          </Form>
          
          {
            stockOutList.length !== 0 && (
              <div className='stockOutList-style mt-5'>
                <table className='table table-striped stockOut-form'>
                  <thead>
                    <tr>
                      <th>Producto</th>
                      <th>Cantidad</th>
                      <th>Precio Salida / U</th>
                      <th>Precio Total Salida</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      stockOutList.map((item, index) => (
                        <tr key={index}>
                          <td>{products.find(product => product._id === item.itemId).productName}</td>
                          <td>{item.quantity} {products.find(product => product._id === item.itemId).unit}</td>
                          <td>${Number(item.pricePerUnit).toFixed(2)}</td>
                          <td>${Number(item.pricePerUnit*item.quantity).toFixed(2)}</td>
                          <td className='pointer' onClick={() => setStockOutList(stockOutList.filter(entry => entry.itemId !== item.itemId))}><FontAwesomeIcon icon={faTrash} size="xs" /></td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            )
          }

          <div className='stockOut-form'>
            <Form.Group className="mt-3" controlId="formBasicCause">
              <Form.Label>Motivo</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Leave a comment here"
                style={{ height: '100px', resize: 'none' }}
                onChange={(e) => setCauseNote(e.target.value)}
                value={causeNote}
                className={sendDataError.causeNote ? "border border-danger" : ""}
              />
            </Form.Group>
            <span>[{causeNote.length}/200]</span>
            {
              Object.keys(sendDataError).length !== 0
                ? Object.values(sendDataError).map((error, index) => (
                  <div className="border-danger w-100 text-danger text-center mt-3 thick-border rounded" key={index}>{error}</div>
                  )) : null
            }
            <Button variant='secondary' className='w-100 my-3' disabled={stockOutList.length === 0} onClick={sendData}>Enviar</Button>
          </div>
        </div>
        <PopUp popUp={successPopUp} greenBorder={true} setPopUp={setSuccessPopUp} popUpTitle={"Venta Extraordinaria Generada Correctamente"} popUpBtnFunction={() => navigate('/home')} popUpBtnText={"OK"} noWayOfClose={true} />
        <PopUp popUp={errorPopUp} setPopUp={setErrorPopUp} popUpTitle={"ERROR"} popUpText={Object.values(dbErrors).join(', ')} redBorder={true} popUpBtnFunction={popUpBtnFunction} popUpBtnText={"OK"} noWayOfClose={true} />
      </AdminRoute>
    </Layout>
  );
};

export default StockOutPage;