import './Sheet.css';

const Sheet = ({sheetActive, setSheetActive, children}) => {
  return (
    <div className={`sheet bg-light border-top border-2 rounded-top border-dark pt-2 ${sheetActive ? "sheet_active" : ""}`}>
      <div className='w-100 text-end px-4'><span className='fs-4 border border-dark pointer px-2 waste-btn rounded' onClick={() => setSheetActive(false)}>X</span></div>
      <div className='px-3'>{children}</div>
    </div>
  );
};

export default Sheet;