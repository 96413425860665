import { Form, Spinner, Table } from 'react-bootstrap';
import Layout from '../../components/Layout/Layout';
import AdminRoute from '../../routes/AdminRoute';
import './DriverViewPage.css';
import { useEffect, useState } from 'react';
import axiosClient from '../../settings/axiosClient';
import { firstUpperCase } from '../../helpers/firstUpperCase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import PopUp from '../../components/PopUp/PopUp';

const DriverViewPage = () => {
  const [onlineDrivers, setOnlineDrivers] = useState([]);
  const [onlineDriversDB, setOnlineDriversDB] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorPopUp, setErrorPopUp] = useState(false);
  const [successPopUp, setSuccessPopUp] = useState(false);

  const getOnlineDrivers = async () => {
    try {
      const response = await axiosClient.get('/driver/online');
      if(response.status === 200){
        setOnlineDrivers(response.data);
        setOnlineDriversDB(response.data);
      };
    } catch (error) {
      setErrors({server: 'Error en el servidor, intente nuevamente.'});
    }
  }

  const handleChange = (e, driverId) => {
    setOnlineDrivers(onlineDrivers.map(driver => {
      if(driver._id === driverId) return {...driver, [e.target.name]: e.target.checked};
      return driver;
    }))
  }

  const updateDriver = async (driver) => {
    if(onlineDriversDB.find(item => item._id === driver._id).active === driver.active && onlineDriversDB.find(item => item._id === driver._id).online === driver.online) return;
    setSpinner(true);
    try {
      const response = await axiosClient.post('/driver/online', driver);
      if(response.status === 200){
        setOnlineDriversDB(onlineDriversDB.map(driverDB => {
          if(driverDB._id === driver._id) return driver;
          return driverDB;
        }))
        setSuccess(true);
      }
    } catch (error) {
      setErrors({server: 'Error en el servidor, intente nuevamente.'});
    }
  }

  const errorPopUpFunction = () => {
    setErrorPopUp(false); 
    setErrors({});
  }

  useEffect(() => {
    if(onlineDrivers.length === 0) getOnlineDrivers();
  }, [])

  useEffect(() => {
    if(success){
      setSpinner(false);
      setSuccess(false);
      setSuccessPopUp(true);
    }
  }, [success])

  useEffect(() => {
    if(Object.keys(errors).length !== 0){
      setSpinner(false);
      setErrorPopUp(true);
    }
  }, [errors])

  return (
    <Layout>
      <AdminRoute>
        <div className='driverViewPage-style pt-5 px-3'>
          {
            spinner ? <div className="form-spinner"><Spinner /></div> : null
          }
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Nombre Completo</th>
                <th>Nombre</th>
                <th>WhatsApp</th>
                <th>Email</th>
                <th>Online</th>
                <th>Activo</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                onlineDrivers.map((driver, index) => (
                  <tr key={index}>
                    <td>{firstUpperCase(driver.userFullName ?? "undefined")}</td>
                    <td>{firstUpperCase(driver.userShortName ?? "undefined")}</td>
                    <td>+{driver.userPhone}</td>
                    <td>{driver.userEmail}</td>
                    <td>
                      <Form.Check
                        type='switch'
                        id={`custom-switch-${index}`}
                        label={driver.online ? 'Si' : 'No'}
                        onChange={(e) => handleChange(e, driver._id)}
                        defaultChecked={driver.online}
                        name='online'
                        disabled={!driver.online}
                      />
                    </td>
                    <td>
                      <Form.Check
                        type='switch'
                        id={`custom-switch-${index}`}
                        label={driver.active ? 'Si' : 'No'}
                        onChange={(e) => handleChange(e, driver._id)}
                        defaultChecked={driver.active}
                        name='active'
                      />
                    </td>
                    <td className='text-center'><FontAwesomeIcon icon={faRefresh} className={onlineDriversDB.find(item => item._id === driver._id).active === driver.active && onlineDriversDB.find(item => item._id === driver._id).online === driver.online ? '' : 'pointer text-success'} onClick={() => updateDriver(driver)} /></td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
          <PopUp popUp={errorPopUp} setPopUp={setErrorPopUp} popUpTitle={"ERROR"} popUpText={Object.values(errors).join(', ')} redBorder={true} popUpBtnFunction={errorPopUpFunction} popUpBtnText={"OK"} noWayOfClose={true} />
          <PopUp popUp={successPopUp} greenBorder={true} setPopUp={setSuccessPopUp} popUpTitle={"Datos Almacenados"} popUpText={"Status Actualizado con éxito."} popUpBtnFunction={() => setSuccessPopUp(false)} popUpBtnText={"OK"} />
        </div>
      </AdminRoute>
    </Layout>
  );
};

export default DriverViewPage;