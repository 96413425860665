import { Button, Spinner, Table } from 'react-bootstrap';
import Layout from '../../components/Layout/Layout';
import AdminRoute from '../../routes/AdminRoute';
import './StockReadPage.css';
import { useContext, useEffect, useState } from 'react';
import ProductsContext from '../../context/Products/ProductsContext';
import PopUp from '../../components/PopUp/PopUp';
import StockReadRow from '../../components/StockReadRow/StockReadRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faRefresh } from '@fortawesome/free-solid-svg-icons';
import axiosClient from '../../settings/axiosClient';
import Sheet from '../../components/Sheet/Sheet';
import UserContext from '../../context/Users/UserContext';

const StockReadPage = () => {
  const { stock, getStock, products, getAllProducts, updateStockContext } = useContext(ProductsContext);
  const { sign } = useContext(UserContext);
  const [errorPopUp, setErrorPopUp] = useState(false);
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [productsData, setProductsData] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [sheetActive, setSheetActive] = useState(false);
  const [stockSelected, setStockSelected] = useState({});
  const [productWaste, setProductWaste] = useState(0);
  const [wasteArray, setWasteArray] = useState([]);
  const [extSales, setExtSales] = useState([]);

  const getStockFunction = async () => {
    const getStockErrors = await getStock();
    const getProductsErrors = await getAllProducts();
    if(Object.keys(getStockErrors). length !== 0 || Object.keys(getProductsErrors). length !== 0){
      if(Object.keys(getProductsErrors). length !== 0){
        setErrors(getProductsErrors);
      }else{
        setErrors(getStockErrors);
      }
      return;
    };
    setSuccess(true);
  }

  const getOrders = async () => {
    try {
      const response = await axiosClient.get("/orders");
      if(response.status === 200) {
        const allOrdersComming = response.data.reverse();
        setAllOrders(allOrdersComming.filter(order => order.totalPrice !== 0));
      }

      const extSales = await axiosClient.get('/sale');
      if(extSales.status === 200) setExtSales(extSales.data);
    } catch (error) {
      setErrors({server: 'Error en el servidor, intente nuevamente.'});
    }
  }

  const getWastes = async () => {
    try {
      const response = await axiosClient.get('/waste');
      if(response.status === 200){
        setWasteArray(response.data);
      }
    } catch (error) {
      setErrors({server: 'Error en el servidor, intente nuevamente.'});
    }
  } 

  const popUpBtnFunction = () => {
    setErrorPopUp(false);
    setErrors({});
  }

  const sendWaste = async () => {
    setSpinner(true);
    try {
      const dataDB = {
        sign,
        itemId: stockSelected.itemId,
        quantity: productWaste,
        soldUnit: stockSelected.unit,
        stockId: stockSelected._id
      }
      const response = await axiosClient.post('/waste', dataDB);
      if(response.status === 200){
        setWasteArray([...wasteArray, response.data.waste]);
        setProductWaste(0);
        await updateStockContext(response.data.stockUpdated);
        setSheetActive(false);
        setSuccess(true);
      }
    } catch (error) {
      setErrors({server: 'Error en el servidor, intente nuevamente.'});
    }
  }

  useEffect(() => {
    if(stock.length === 0 || products.length === 0){
      setSpinner(true);
      getStockFunction();
    }
    if(allOrders.length === 0) getOrders();
    if(wasteArray.length === 0) getWastes();
  }, [])
  
  useEffect(() => {
    if(Object.keys(errors).length !== 0){
      setSpinner(false);
      setErrorPopUp(true);
    }
  }, [errors])
  
  useEffect(() => {
    if(success){
      setSpinner(false);
      setSuccess(false);
    }
  }, [success])

  useEffect(() => {
    if(products.length !== 0) setProductsData(products.filter(product => product.showItem && stock.filter(entry => entry.itemId === product._id).length !== 0));
  }, [products, stock])

  useEffect(() => {
    if(Object.keys(stockSelected).length !== 0){
      setSheetActive(true);
    }
  }, [stockSelected])

  useEffect(() => {
    if(!sheetActive){
      setStockSelected({});
      setProductWaste(0);
    }
  }, [sheetActive])

  return (
    <Layout>
      <AdminRoute>
        <div className='stockReadPage-style pt-5 px-2'>
          {
            spinner ? <div className="form-spinner"><Spinner /></div> : null
          }
          <div><small className='fst-italic'>IMPORTANTE: La cantidad de producto de los diferentes lotes puede ser editada una única vez antes de los 10 días de la carga del lote.</small></div>
          <div><small className='fst-italic'>Estoy dejando afuera las compras y ventas del día de testeo en Octubre.</small></div>
          <Table bordered hover>
            <thead>
              <tr>
                <th></th>
                <th className='text-center'>LoteId</th>
                <th className='text-center'>Entrada</th>
                <th>Producto</th>
                <th className='text-center'>Q lote <FontAwesomeIcon icon={faEdit} /></th>
                <th className='text-center'><FontAwesomeIcon icon={faRefresh} /></th>
                <th className='text-center'>Remanente</th>
                <th className='text-center'>Desperdicio <FontAwesomeIcon icon={faEdit} /></th>
                <th className='text-center'>Vendido</th>
                <th className='text-center'>Pend. Venta</th>
                <th className='text-center'>Venta Ext.</th>
                <th className='text-center'>Un.</th>
                <th className='text-center'>Costo</th>
                <th className='text-center'>Venta</th>
                <th className='text-center'>[%]</th>
              </tr>
            </thead>
            <tbody>
              {
                productsData.map((item, index) => <StockReadRow key={index} product={item} stock={stock} setErrors={setErrors} setSpinner={setSpinner} setSuccess={setSuccess} allOrders={allOrders} setStockSelected={setStockSelected} wasteArray={wasteArray} extSales={extSales} />)
              }
            </tbody>
          </Table>
        </div>
        <Sheet sheetActive={sheetActive} setSheetActive={setSheetActive} stockSelected={stockSelected}> 
          {
            stockSelected._id && (
              <>
                <div className='w-100 text-center fs-5 text-decoration-underline fw-bold mb-3'>{products.find(item => item._id === stockSelected.itemId).productName}</div>
                <div className="d-flex justify-content-center">
                  <div className="border border-dark rounded me-5 p-3">
                    <div><span className='fw-bold'>Entrada lote: </span>{stockSelected.createdAt.substring(0, 10).split('-').reverse().join('/')}</div>
                    <div><span className='fw-bold'>Días Almacenamiento: </span>{((new Date().getTime() - new Date(stockSelected.createdAt).getTime())/(24*60*60*1000)).toFixed(1)}</div>
                    <div><span className='fw-bold'>Cantidad Entrada: </span>{stockSelected.wholeQuantity + stockSelected.unit}</div>
                    <div><span className='fw-bold'>Remanente: </span>{stockSelected.wholeQuantityUpdated + stockSelected.unit}</div>
                    <div><span className='fw-bold'>Desperdicio: </span>{stockSelected.waste + stockSelected.unit}</div>
                    <div><span className='fw-bold'>Vendido: </span>{stockSelected.sold + stockSelected.unit}</div>
                    <div><span className='fw-bold'>Pendiente de Venta: </span>{stockSelected.soldPend === "0.00" ? "-" : stockSelected.soldPend + stockSelected.unit}</div>
                  </div>
                  <div className="border border-dark rounded ms-5 p-3">
                    <div>CARGAR DESPERDICIO</div>
                    <div className='w-100 d-flex mt-2'>
                      <div className="d-flex w-100 justify-content-center">
                        <input autoComplete="off" className="form-control wpx-70 text-center" type="number" step=".01" onChange={(e) => setProductWaste(e.target.value)} value={productWaste} />
                        <input autoComplete="off" required className="form-control wpx-40 ms-1" type="text" readOnly value={stockSelected.unit} disabled />
                      </div>
                    </div>
                    <Button variant='outline-dark' className='w-100 mt-2' onClick={sendWaste} disabled={productWaste == 0 || productWaste > stockSelected.wholeQuantityUpdated}>Cargar</Button>
                  </div>
                </div>
              </>
            )
          }
        </Sheet>
        <PopUp popUp={errorPopUp} setPopUp={setErrorPopUp} popUpTitle={"ERROR"} popUpText={Object.values(errors).join(', ')} redBorder={true} popUpBtnFunction={popUpBtnFunction} popUpBtnText={"OK"} noWayOfClose={true} />
      </AdminRoute>
    </Layout>
  );
};

export default StockReadPage;