import { Table } from 'react-bootstrap';
import './StockViewTable.css';
import { useContext, useEffect, useState } from 'react';
import ProductsContext from '../../context/Products/ProductsContext';
import StockViewRow from './StockViewRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import Spinner from '../Spinner/Spinner';
import PopUp from '../PopUp/PopUp';

const StockViewTable = ({ showTable, setShowTable, entrySelected, setEntrySelected, stockShowed, wasteArray }) => {
  const { updateSalesPrice } = useContext(ProductsContext);
  const [spinner, setSpinner] = useState(false);
  const [errorPopUp, setErrorPopUp] = useState(false);
  const [successPopUp, setSuccessPopUp] = useState(false);
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  
  const updatePrice = async (entryId, itemSalesPrice) => {
    if(spinner) return;
    setSpinner(true);
    const updateError = await updateSalesPrice(entryId, itemSalesPrice);
    if(Object.keys(updateError).length !== 0){
      setErrors(updateError);
      return;
    }
    setSuccess(true);
  }
  
  useEffect(() => {
    if(success){
      setTimeout(() => {
        setSpinner(false);
        setSuccess(false);
        setSuccessPopUp(true);
        setEntrySelected(-1);
      }, 500)
    }
  }, [success])

  useEffect(() => {
    if(Object.keys(errors).length !== 0){
      setTimeout(() => {
        setSpinner(false);
        setErrorPopUp(true);
        setErrors({});
      }, 500)
    }
  }, [errors])

  useEffect(() => {
    if(!showTable){
      setEntrySelected(-1);
    }
  }, [showTable])

  return (
    <div className='m-5'>
      {
        spinner ? <div className="form-spinner"><Spinner /></div> : null
      }
      <div className='py-1 rounded w-100 text-center pointer comp-btn-style bg-light mb-3' onClick={() => setShowTable(!showTable)}>Actualizar Precios de Venta por Stock<span className='ms-1' ><FontAwesomeIcon icon={showTable ? faAngleUp : faAngleDown} /></span></div>
      <Table striped bordered className={`stockViewTable-style rounded ${showTable ? null : "d-none"}`}>
        <thead>
          <tr>
            <th className='text-center'>Entrada</th>
            <th className='text-center'>Producto</th>
            <th className='text-center'>Cantidad</th>
            <th className='text-center'>Costo</th>
            <th className='text-center'>Desp.</th>
            <th className='text-center'>Costo / U</th>
            <th className='text-center'>Remanente</th>
            <th className='text-center'>Precio Venta <span><FontAwesomeIcon className='text-warning ms-2' icon={faPenToSquare} /></span></th>
            <th className='text-center'>[%]</th>
            <th className='text-center'></th>
            <th className='text-center'></th>
          </tr>
        </thead>
        <tbody>
          {
            stockShowed?.filter(item => item.wholeQuantityUpdated !== 0).map((entry, index) => (
              <StockViewRow entry={entry} key={index} updatePrice={updatePrice} entrySelected={entrySelected} setEntrySelected={setEntrySelected} wasteArray={wasteArray} index={index} />
            ))
          }
        </tbody>
      </Table>
      <PopUp popUp={errorPopUp} setPopUp={setErrorPopUp} popUpTitle={"ERROR"} popUpText={Object.values(errors).join(', ')} redBorder={true} popUpBtnFunction={() => setErrorPopUp(false)} popUpBtnText={"OK"} />
      <PopUp popUp={successPopUp} greenBorder={true} setPopUp={setSuccessPopUp} popUpTitle={"Datos Almacenados"} popUpText={"Precio de Venta Actualizado con éxito"} popUpBtnFunction={() => setSuccessPopUp(false)} popUpBtnText={"OK"} />
    </div>
  );
};

export default StockViewTable;